import styles from './styles';
import React, { useState, useEffect } from 'react'
import Nav from '../../components/Nav';
import Container from '../../components/Container';
import Hero from './components/Hero';
import { SUPPORT_EMAIL, DEMO_URL, APP_URL, MAIL_TO_SUPPORT_URL } from '../../settings';
import Button from '../../components/Button'
import Comparison from './components/Comparison';
import { isMobile } from "react-device-detect";
import Features from './components/Features';
import Steps from './components/Steps';
import Focus from './components/Focus';
import Footer from './components/Footer';

function Landing({ }) {

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        checkAuthed()
    }, []);

    const support = () => {
        window.location.href = MAIL_TO_SUPPORT_URL
    }

    const launch = () => {
        window.open(APP_URL)
    }

    const checkAuthed = async () => {
        let token = await localStorage.getItem('jwt')
        console.log('token in home: ', token)
        if (!!token) {
            window.location.href = './connect'
        }
        setLoading(false)
    }

    const scheduleDemo = () => {
        window.open(APP_URL)
    }

    const getStarted = () => {
        window.location.href = APP_URL
    }

    const login = () => {
        window.location.href = '/login'
    }

    const goToDocs = () => {

    }

    const returnDetailDesc = () => {
        return 'Access pay in real time from nearly any employer. Stream is your 24/7 financial companion for navigating surprise bills, expenses, and more. Get started today.'
    }

    if (loading) {
        return null
    }

    if (isMobile) {
        return (
            <Container>
                <Container className={styles.mobileHeader}>
                    Stream
                </Container>
                <Container className={styles.firstImage}>
                </Container>
                <Container className={styles.header}>Never wait for your pay 💰
                </Container>
                <Container className={styles.mobileBackground}>{returnDetailDesc()}
                </Container>

                <Button primary={true} onItemPress={launch} className={styles.mobileBtn} text={"Try Now"}></Button>
                <Button onItemPress={support} className={styles.mobileBtn} text={"Contact Support"}></Button>
            </Container>
        )
    }

    return (
        <Container className={styles.background}>
            <Nav scheduleDemo={scheduleDemo} getStarted={getStarted} login={login} showButtons={true} />
            <Hero scheduleDemo={scheduleDemo} getStarted={getStarted} />
            <Steps scheduleDemo={scheduleDemo} getStarted={getStarted} />
            {/* <Focus scheduleDemo={scheduleDemo} getStarted={getStarted} /> */}
            <Comparison scheduleDemo={scheduleDemo} getStarted={getStarted} />
            {/* <Features scheduleDemo={scheduleDemo} getStarted={getStarted} /> */}
            <Footer scheduleDemo={scheduleDemo} getStarted={getStarted} />
        </Container>
    );
}

export default Landing;
