import styles from './styles';
import React, { useState, useEffect } from 'react'
import Container from '../../../../components/Container';
import Button from '../../../../components/Button';
import Text from '../../../../components/Text';

function Steps({ scheduleDemo, getStarted }) {

    const STEPS_EXPLAINED = [
        {
            title: 'Join',
            caption: 'Create your account for free.'
        },
        {
            title: 'Link',
            caption: 'Link your payroll provider and bank account.'
        },
        {
            title: 'Stream',
            caption: 'Stream your expenses to stay on top of your savings!'
        },
    ]

    const returnSteps = () => {
        return (
            <Container className={styles.stepsList}>
            {STEPS_EXPLAINED.map((step, index) => (
                    <Container className={styles.stepsItemContainer} key={index}>
            
                        <Text className={styles.stepTitleText} text={step.title} />
                        <Text className={styles.stepCaptionText} text={step.caption} />
                    </Container>
            ))}
            </Container>
        )
    }

    const returnLeftSection = () => {
        return (

            <Container className={styles.section}>
                <Container className={styles.firstImage}>
                </Container>
            </Container>
        )
    }

    // TODO write a general component in components for this
    const returnTryForFreeCTA = () => {
        return (
            <Button text={'Try Now'} onItemPress={getStarted} primary={true} className={styles.btn} />
        )
    }

    const returnRightSection = () => {
        return (
            <Container className={styles.section}>
                <Text className={styles.summaryText} text={'EASY AND FAST'} />
                <Text className={styles.mainText} text={'Set up effortlessly'} />
                {returnSteps()}
                {returnTryForFreeCTA()}
            </Container>
        )
    }

    return (
        <Container className={styles.background}>
            <Container className={styles.innerPanel}>
                {returnLeftSection()}
                {returnRightSection()}
            </Container>

        </Container>
    );
}

export default Steps;
