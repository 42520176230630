import styles from './styles';
import React, { useState, useEffect } from 'react'
import Container from '../../../../components/Container';
import Button from '../../../../components/Button';
import Text from '../../../../components/Text';
import { SUPPORT_EMAIL } from '../../../../settings';

function Footer({ scheduleDemo, getStarted }) {

    const returnLeftSection = () => {
        return (
            <Container className={styles.section}>
                <Text className={styles.footerTitle} text={'Available on iOS'} />
                <Text
                    className={styles.footerTerms}
                    text={'Terms & Policies'}
                    url="/terms"  // Example link
                />
                <Text className={styles.footerCaption} text={'Try now on the Apple Store.'} />
                <Text className={styles.footerCaption} text={'Available in the US only. Fees apply. Product of StreamFunds, Inc.'} />

                {/* Link to Terms and Policies */}
                <Text className={styles.footerCaption}>
                    <a href="/terms" target="_blank" rel="noopener noreferrer">
                        Terms and Policies
                    </a>
                </Text>

                {/* You can uncomment and use the following image containers if needed */}
                {/* 
                <Container className={styles.firstImage}></Container>
                <Container className={styles.secondImage}></Container>
                <Container className={styles.thirdImage}></Container>
                <Container className={styles.fourthImage}></Container>
                */}
            </Container>
        );
    }

    // TODO write a general component in components for this
    const returnTryForFreeCTA = () => {
        return (
            <Button text={'Try Now'} onItemPress={scheduleDemo} primary={true} className={styles.btn} />
        )
    }

    const returnRightSection = () => {
        return (
            <Container className={styles.section}>
                <Container className={styles.menuItems}>
                    {returnTryForFreeCTA()}
                </Container>
            </Container>
        )
    }

    return (
        <Container className={styles.background}>
            <Container className={styles.upperContainer}>

                <Container className={styles.innerPanel}>
                    {returnLeftSection()}
                    {returnRightSection()}

                </Container>
                <Container className={styles.spacer} />
            </Container>


        </Container>
    );
}

export default Footer;
