import styles from './styles';
import { isMobile } from "react-device-detect";
import React, { useState, useEffect } from 'react';
import Container from '../../../../components/Container';
import Button from '../../../../components/Button';
import Text from '../../../../components/Text';
import { DEMO_URL, MAIL_TO_SUPPORT_URL, APP_URL } from '../../../../settings';

function Hero({ scheduleDemo, getStarted }) {

    const returnDetailDesc = () => {
        return 'Access pay in real time from nearly any employer. Stream is your 24/7 financial companion for navigating surprise bills, expenses, and more. Get started today.'
    }

    const quickstart = () => {
        window.location.href = './quickstart'
    }

    const sample = () => {
        window.location.href = MAIL_TO_SUPPORT_URL
    }

    const docs = () => {
        window.location.href = './documentation'
    }

    const returnLeftSection = () => {
        return (
            <Container className={styles.section}>
                <Text className={styles.heroText} text={'Never wait'} />
                <Text className={styles.heroText} text={'for pay 💰'} />
                <Container className={styles.swathImage}></Container>
                <Text className={styles.detailText} text={returnDetailDesc()} />
                <Container className={styles.buttonsContainer}>
                    <Button text={'Try Now'} onItemPress={getStarted} primary={true} className={styles.btn} />
                    <Button text={'Contact Support'} onItemPress={sample} className={styles.btn} />
                </Container>
            </Container>
        )
    }

    const returnRightSection = () => {
        return (
            <Container className={styles.section}>
                <Container className={isMobile ? `${styles.mobileImage} ${styles.firstImage}` : `${styles.desktopImage} ${styles.firstImage}`}></Container>
            </Container>
        )
    }

    return (
        <Container className={styles.background}>
            <Container className={styles.innerPanel}>
                {returnLeftSection()}
                {returnRightSection()}
            </Container>
        </Container>
    );
}

export default Hero;
